<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Profil</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="!error" :fullscreen="true">

      <ion-row class="sideMargins topMargins">
        <ion-col size="12" style="height: 250px; width: 100%; position: relative; margin-bottom: 50px;">
          <img class="rounded-image imageCircle" src="http://via.placeholder.com/250x250.png?text=Avatar" alt="test" style="max-width: 100%;max-height: 100%;overflow: auto;margin: auto;position: absolute;top: 0; left: 0; bottom: 0; right: 0;" />
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Benutzername" :disabled="true">Benutzername</ion-label>
            <ion-input v-model="user.username"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-label position="stacked" placeholder="Vorname">Vorname</ion-label>
            <ion-input v-model="user.firstname"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-label position="stacked" placeholder="Nachname">Nachname</ion-label>
            <ion-input v-model="user.lastname"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Geburtsdatum" :disabled="true">Geburtsdatum</ion-label>
            <ion-datetime v-model="user.birthdate" display-format="DD.MM.YYYY"></ion-datetime>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-button shape="round" style="width: 95%; margin-left: 5%;">Speichern</ion-button>
        </ion-col>
      </ion-row>

    </ion-content>

    <ion-content v-if="error">
        {{ this.errorMsg }}
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonInput, IonLabel, IonItem, IonDatetime, IonButton } from '@ionic/vue';
import axios from "axios";

export default {
  name: 'Profile',
  data () {
    return {
      user: null,
      finishedLoading: false,
      error: false,
      errorMsg: null
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonInput,
    IonLabel,
    IonItem,
    IonDatetime,
    IonButton
  },
  created() {

    this.user = {
      "username": "",
      "firstname": "",
      "lastname": "",
      "birthdate": ""
    };

    // check if user is logged in
    if (localStorage.userToken && localStorage.userId){
      axios.get(process.env.VUE_APP_API_URL + '/users/' + localStorage.userId, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(response => {
            this.user = response.data;
          })
          .catch(error => {
            this.error = true;
            this.errorMsg = "User Session is expired.";

            this.$router.push({ path: '/login' })

          })
          .finally(() => {
            this.finishedLoading = true;
          });
    } else {
      this.error = true;
      this.errorMsg = "No Active User Session found.";
    }


  }
}
</script>

<style scoped>

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 10%;
}

.imageCircle {
  border: 2px solid #1dc0c6;
}

.rounded-image {
  border-radius: 50%;
}

</style>