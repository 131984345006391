
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonInput, IonLabel, IonItem, IonDatetime, IonButton } from '@ionic/vue';
import axios from "axios";

export default {
  name: 'Profile',
  data () {
    return {
      user: null,
      finishedLoading: false,
      error: false,
      errorMsg: null
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonInput,
    IonLabel,
    IonItem,
    IonDatetime,
    IonButton
  },
  created() {

    this.user = {
      "username": "",
      "firstname": "",
      "lastname": "",
      "birthdate": ""
    };

    // check if user is logged in
    if (localStorage.userToken && localStorage.userId){
      axios.get(process.env.VUE_APP_API_URL + '/users/' + localStorage.userId, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(response => {
            this.user = response.data;
          })
          .catch(error => {
            this.error = true;
            this.errorMsg = "User Session is expired.";

            this.$router.push({ path: '/login' })

          })
          .finally(() => {
            this.finishedLoading = true;
          });
    } else {
      this.error = true;
      this.errorMsg = "No Active User Session found.";
    }


  }
}
